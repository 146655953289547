
import { session } from '@/plugins/store';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class ForgetPassword extends Vue {
  @Prop(Boolean) readonly value: boolean | undefined;
  @Prop(Boolean) readonly processing: boolean | undefined;

  email = '';

  @Watch('value')
  async onShow() {
    if (this.value) {
      this.email = '';
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value)
  }

  get errorMessage() {
    return session.errorMessage;
  }

  get mailAddress() {
    return session.user.userLogin;
  }

  doAction() {
    session.pushError(null)
    this.$emit('action', this.email)
  }

  resend() {
    this.$emit('resend')
  }
}
