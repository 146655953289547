
import { Component, Vue, Prop } from 'vue-property-decorator'
import $ph from '@/plugins/phoenix'
import { session } from '@/plugins/store'

@Component
export default class PwdChangeDialog extends Vue {
  @Prop() readonly value: any;

  currentError = false;
  showOld = false;
  pwdOldValue = '';
  show = false;
  pwdValue = '';
  pwdConfirm = '';
  processing = false;
  validator: Record<string, boolean | string> = {};

  get variable() {
    if (!this.value) {
      this.pwdOldValue = ''
      this.pwdValue = ''
      this.pwdConfirm = ''
    }
    return this.value;
  }

  set variable(value: any) {
    this.$emit('input', value)
  }

  get valid() {
    for (var v in this.validator) {
      if (this.validator[v] !== true) return false
    }
    return true
  }

  get complexError() {
    return !this.pwdValue || this.pwdValue.length < 8 ? $ph.i18n('system.passwordNotComplex') : null
  }

  get confirmError() {
    return this.pwdValue === this.pwdConfirm ? null : $ph.i18n('system.passwordNotMatch')
  }

  async change() {
    this.processing = true
    this.currentError = false;
    try {
      await session.userSetPass({ oldPwd: this.pwdOldValue, pwd: this.pwdValue })
      session.showNotification({ message: 'system.passwordChanged', type: 'info', timeout: 3000 })
      this.variable = false
    } catch (error) {
      this.currentError = true;
      session.pushError(error)
    }
    this.processing = false
  }
}
