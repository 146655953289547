
import settings from '@/plugins/settings';
import { session } from '@/plugins/store';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IAuthorisation, IRegistration } from '../types';

@Component
export default class LoginDialog extends Vue {
  @Prop(Boolean) readonly value: boolean | undefined;
  @Prop(Boolean) readonly processing: boolean | undefined;

  tab = 0;

  login = '';
  pwd = '';

  email = '';
  regpwd = '';

  firstName = '';
  lastName = '';
  country = '';
  agree = false;
  mailing = true;

  initialized = false;
  pwdEntered = false;
  pwdVisible = false;

  @Watch('value')
  async onShow() {
    if (this.value) {
      this.login = '';
      this.pwd = '';
      if (this.startTab === 'register') {
        this.tab = 1
      }
    }
  }

  @Watch('tab')
  async onTab() {
    if (this.tab === 1 && !this.initialized) {
      await session.onboard()
      this.initialized = true
      this.pwdEntered = this.regpwd !== ''
    }
  }

  @Watch('errorMessage')
  async onError() {
    if (this.errorMessage !== '' && this.tab === 0) {
      this.focusPwd();
    }
  }

  get invalidPassword(): boolean {
    const re = /^(?=.*[0-9])(?=.*[-_~'"`= /\\[\]{}()+.,:;^<>!?@#$%^&*])[a-zA-Z0-9\-_~'"`= /\\[\]{}()+.,:;^<>!?@#$%^&*]{8,50}$/;
    return this.pwdEntered && !re.test(this.regpwd)
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value)
  }

  get errorMessage() {
    return session.errorMessage;
  }

  get countries() {
    return session.onboardCountries;
  }

  get docsLink() {
    return settings.company.companyDocsURL;
  }

  get isRegistrationAllowed() {
    return settings.company.companyUISettings.registration.selfRegistration;
  }

  get startTab() {
    const urlParts = document.URL.split('#');
    return (urlParts && urlParts.length > 1) ? urlParts[1] : null;
  }

  focusPwd() {
    if (this.login !== '') {
      this.$nextTick(() => {
        (this.$refs.pwd as any).$refs.vuetifyInput.$refs.input.focus()
      })
    }
  }

  doResetPassword() {
    this.$emit('resetPass')
  }

  doAction() {
    session.pushError(null)

    if (this.tab === 0) {
      const auth: IAuthorisation = {
        userLogin: this.login,
        userPwd: this.pwd,
      }

      this.$emit('auth', auth)

      this.pwd = '';
    } else {
      const register: IRegistration = {
        userFirstName: this.firstName,
        userLastName: this.lastName,
        userLanguage: session.currentLanguage.code,
        userLogin: this.email,
        userPwd: this.regpwd,
        customerCountry: this.country,
        mailing: this.mailing,
        phone: '',
      }

      this.$emit('register', register)
    }
  }
}
